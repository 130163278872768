import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"

export const query = graphql`
  query caseStudies {
    wpcontent {
      caseStudies(first: 99, where: { orderby: { field: DATE, order: DESC } }) {
        nodes {
          id
          title
          uri
          date
          publishedDate
          excerpt
          coverImage
        }
      }
      seo {
        contentTypes {
          caseStudy {
            metaDesc
            title
          }
        }
      }
    }
  }
`

const CaseStudies = ({ data }) => {
  const [loadedContent, setLoadedContent] = useState(7)
  const [showHidden, setShowHidden] = useState(false)
  const [renderJSX, setRenderJSX] = useState([])
  // console.log(data)
  const casestudies = data.wpcontent.caseStudies.nodes
  const caseStudySeo = data.wpcontent.seo.contentTypes.caseStudy
  const pageTitle = caseStudySeo.title
  const metaDesc = caseStudySeo.metaDesc

  // const defaultJSX = () => {
  //     return(
  //         <div className="resource-listing mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 justify-center items-center">
  //                 {casestudies.map((casestudy, index) => {
  //                         if(index < 7){
  //                             return(
  //                             <article key={casestudy.id} className = "flex justify-center items-center">
  //                                 <div className="relative z-1 bg-white p-5 card-1 mb-3 mt-3 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out justify-center items-center">
  //                                     <img loading="lazy" className="pb-0 rounded-sm" src={casestudy.coverImage} height = "179" width = "287" alt={casestudy.title}/>
  //                                     <div className="bg-white py-6 pb-2 h-full">
  //                                         <div className="box-subtitle mb-2" dangerouslySetInnerHTML={{ __html: casestudy.publishedDate }} />
  //                                         <h2 className="box-title font-bold mb-3 line-clamp-2" dangerouslySetInnerHTML={{ __html: casestudy.title }} />
  //                                         {/* <div className="text-gray-800 text-lg line-clamp-3 mb-3" dangerouslySetInnerHTML={{ __html: casestudy.excerpt }} /> */}
  //                                         <div className="learnmore-btn mb-0 text1">
  //                                             <Link className="text-purple-500 inline-flex items-center" to={`${casestudy.uri}`}>
  //                                                 <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
  //                                                 <span className="text-purple-500 font-medium text-sm uppercase">Read now</span>
  //                                                 <svg className="w-6 h-6 top-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><polygon fill="currentColor" points="15 12 9 16 9 8"></polygon></svg>
  //                                             </Link>
  //                                         </div>
  //                                     </div>
  //                                 </div>
  //                             </article>
  //                             )
  //                         }
  //                 })}
  //             </div>
  //     )
  // }

  const defaultJSX = () => {
    return (
      <div className="resource-listing mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 justify-center items-center">
        {casestudies.map((casestudy, index) => {
          return (
            <article
              key={casestudy.id}
              className="flex justify-center items-center"
            >
              <Link to={`${casestudy.uri}`}>
                <div className="relative z-1 bg-white p-5 card-1 mb-3 mt-3 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out justify-center items-center">
                  <img
                    loading="lazy"
                    className="pb-0 rounded-sm mx-auto"
                    src={casestudy.coverImage}
                    height="179"
                    width="320"
                    alt={casestudy.title}
                  />
                  <div className="bg-white py-6 pb-2 h-full">
                    {/* <div
                      className="box-subtitle mb-2"
                      dangerouslySetInnerHTML={{
                        __html: casestudy.publishedDate,
                      }}
                    /> */}
                    <h2
                      className="box-title font-bold mb-3 line-clamp-3"
                      dangerouslySetInnerHTML={{ __html: casestudy.title }}
                    />
                    {/* <div className="text-gray-800 text-lg line-clamp-3 mb-3" dangerouslySetInnerHTML={{ __html: casestudy.excerpt }} /> */}
                    <div className="learnmore-btn mb-0 text1 inline-flex items-center">
                      <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
                      <span className="text-purple-500 font-medium text-sm uppercase">
                        Read now
                      </span>
                      <svg
                        className="w-6 h-6 top-0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <polygon
                          fill="currentColor"
                          points="15 12 9 16 9 8"
                        ></polygon>
                      </svg>
                    </div>
                  </div>
                </div>
              </Link>
            </article>
          )
        })}
      </div>
    )
  }

  //   const addedJSX = () => {
  //     return (
  //       <div
  //         className={
  //           showHidden
  //             ? "mt-5 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 justify-center items-center"
  //             : "hidden"
  //         }
  //       >
  //         {casestudies.map((casestudy, index) => {
  //           if (index >= loadedContent - 6 && index < loadedContent) {
  //             return (
  //               <article
  //                 key={casestudy.id}
  //                 className="loadFromTopAnimationActive flex justify-center items-center"
  //               >
  //                 <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out justify-center items-center">
  //                   <img
  //                     loading="lazy"
  //                     className="pb-0 rounded-sm"
  //                     height="179"
  //                     width="287"
  //                     src={casestudy.coverImage}
  //                     alt={casestudy.title}
  //                   />
  //                   <div className="bg-white py-6 pb-2 h-full">
  //                     <div
  //                       className="box-subtitle mb-2"
  //                       dangerouslySetInnerHTML={{
  //                         __html: casestudy.publishedDate,
  //                       }}
  //                     />
  //                     <h2
  //                       className="box-title font-bold mb-3 line-clamp-2"
  //                       dangerouslySetInnerHTML={{ __html: casestudy.title }}
  //                     />
  //                     {/* <div className="text-gray-800 text-lg line-clamp-3 mb-3" dangerouslySetInnerHTML={{ __html: casestudy.excerpt }} /> */}
  //                     <div className="learnmore-btn mb-0 text1">
  //                       <Link
  //                         className="text-purple-500 inline-flex items-center"
  //                         to={`${casestudy.uri}`}
  //                       >
  //                         <span className="a-stroke w-4 bg-purple-500 mr-2 relative"></span>
  //                         <span className="text-purple-500 font-medium text-sm uppercase">
  //                           Read now
  //                         </span>
  //                         <svg
  //                           className="w-6 h-6 top-0"
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           viewBox="0 0 24 24"
  //                           width="24"
  //                           height="24"
  //                         >
  //                           <polygon
  //                             fill="currentColor"
  //                             points="15 12 9 16 9 8"
  //                           ></polygon>
  //                         </svg>
  //                       </Link>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </article>
  //             )
  //           }
  //         })}
  //       </div>
  //     )
  //   }

  //   const handleLoad = () => {
  //     setLoadedContent(loadedContent + 6)
  //     setShowHidden(true)
  //   }

  //   useEffect(() => {
  //     setRenderJSX([...renderJSX, addedJSX()])
  //   }, [loadedContent])

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>

        <meta name="description" content={metaDesc} />

        <meta property="og:title" content={pageTitle} />

        <meta property="og:description" content={metaDesc} />

        <meta property="twitter:title" content={pageTitle} />

        <meta property="twitter:description" content={metaDesc} />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/case-study/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/case-study/"
        ></link>
      </Helmet>
      <section className="bg-indigo-100 mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-10 relative xl:flex casestudy-hero overflow-hidden">
        <div className="container max-w-7xl sm:text-center px-6 xl:grid grid-cols-2 justify-center items-center">
          <div>
            <h1 className="text-purple-100 text-4xl xl:text-7xl text-center xl:text-left lg:pr-3 xl:w-10/12">
              <span className="flex justify-center xl:justify-start">
                Success&nbsp;
                <span className="text-orange">Stories</span>
              </span>
            </h1>
            <p className="my-8 text-lg xl:text-xl text-purple-100 xl:w-10/12 text-center xl:text-left">
              Read our client success stories and learn how organisations are
              using Vantage Circle to build an engaged and productive workplace.
            </p>
          </div>
          <div>
            <div class="transform translate-y-10 md:translate-y-28 xl:translate-y-10 flex justify-center xl:justify-end items-center md:h-full z-0 mt-6 xl:mt-0 lg:ml-3 px-3">
              <img
                decoding="async"
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/success-stories-hero.png"
                alt="Vantage Rewards"
                width="550"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container px-6 lg:px-0 max-w-6xl mx-auto pt-5 sm:pt-10 md:pt-10 md:-mt-0 flex flex-col justify-center items-center">
        {defaultJSX()}

        {/* {showHidden && renderJSX.map(render => render)} */}

        {/* <div className="w-full flex justify-center items-center my-20">
          <div className="w-1/2 md:w-2/6 lg:w-1/6">
            <button
              className={
                loadedContent < casestudies.length ? "vc-colored-btn" : "hidden"
              }
              onClick={handleLoad}
            >
              Load more
            </button>
          </div>
        </div> */}
      </section>
      <section className="bottom-cta m-6 md:m-0 relative z-1">
        <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden my-20">
          <div className="bottom-cta-content text-center text-white">
            <div className="section-title-white">
              Find Out How Vantage Circle Works
            </div>
            <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
              <div>
                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">
                  Schedule a Free Demo
                </a>
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            className="cta-dot-clip"
            src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"
            alt="VC white dots"
            width="90"
            height="90"
          />
          <img
            loading="lazy"
            className="cta-flower-clip"
            src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"
            alt="VC flower"
            width="150"
            height="150"
          />
        </div>
      </section>
    </Layout>
  )
}

export default CaseStudies
